<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="imagePreview"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.$refs.input.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">اختيار صورة</span>
        </v-btn>
        <p class="text-sm mt-5">
          الصيغ المسموحة JPG, GIF او PNG.
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"

        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="form.name"
              v-max-length="200"
              :rules="rules.name"
              label="اسم المستخدم"
              append-icon="mdi-account"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="form.email"
              v-max-length="200"
              :rules="rules.email"
              label="البريد الالكتروني"
              append-icon="mdi-at"
              type="email"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.password"
              v-max-length="200"
              :rules="rules.password"
              label="كلمة المرور"
              autocomplete="new-password"
              :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
              :type="hidePassword ? 'password' : 'text'"
              required
              outlined
              dense
              @click:append="togglePassword()"
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.password_confirm"
              v-max-length="200"
              :rules="password_confirm"
              label="تاكيد كلمة المرور"
              autocomplete="new-password"
              type="password"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col class="d-none">
            <v-file-input
              ref="refInputEl"
              v-model="form.image"
              :rules="rules.image"
              type="file"
              accept=".jpeg,.png,.jpg,GIF"
              :hidden="true"
              @change="fileChanged"
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              :disabled="!valid"
              @click="submit"
            >
              حفظ التغييرات
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="reset"
            >
              الغاء
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'UserProfile',
  data: () => ({
    valid: true,
    hidePassword: true,
    form: {
      name: '',
      email: '',
      image: null,
      password: '',
      password_confirm: '',
    },
    imagePreview: '',
    rules: {
      name: [
        v => !!v || 'الاسم  مطلوب',
      ],
      password: [
        v => v.length >= 6 || v.length <= 0 || 'كلمة المرور  مطلوب',
      ],
      email: [
        v => !!v || 'البريد الالكتروني مطلوب',
        v => /.+@.+\..+/.test(v) || 'يجب اختيار صيغة صحيحة للبريد الالكتروني',
      ],
      image: [
        v => !v || v.size < 10000000 || 'حجم الصورة التي تم اختيارها اكبر من 10 MB !',
      ],
    },
  }),
  computed: {
    password_confirm() {
      return [
        v => v === this.form.password || 'كلمة المرور  غير متطابقة',
      ];
    },
  },
  setup() {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    return {
      status,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  async mounted() {
    const { data: response } = await axios.get('/auth/me');
    this.form.name = response.data.name;
    this.form.email = response.data.email;
    this.imagePreview = response.data.image.preview_url ?? require('@/assets/images/avatars/1.png');
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate() === false) {
          return
        }
        const formData = new FormData();
        formData.append('image', this.form.image);
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        formData.append('password', this.form.password);
        await axios.post('/auth/update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        await toast.dispatch('success', 'تم التحديث بنجاح.');
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      }
    },
    togglePassword() {
      this.hidePassword = !this.hidePassword;
    },
    fileChanged() {
      if (!this.form.image) {
        this.imagePreview = null

        return null
      }
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
  },
}
</script>

<style scoped>

</style>
